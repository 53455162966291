<template>
  <footer
    class="sticky bottom-0 left-0 right-0 z-10 flex flex-col items-center border-t border-gray-200 bg-white px-4 sm:px-6 lg:px-8"
  >
    <AppointmentStepperBulletSteps
      v-if="route.path.includes('/appointment/new')"
      class="my-2 sm:my-3 md:my-4"
    ></AppointmentStepperBulletSteps>
    <div class="flex flex-col items-center justify-center gap-0.5 py-1 sm:flex-row sm:gap-6">
      <div class="text-sm">&copy; {{ new Date().getFullYear() }} meevo Healthcare GmbH</div>
      <div class="flex w-full max-w-xs justify-around gap-4 text-sm sm:gap-6">
        <span class="cursor-pointer hover:text-gray-500" @click.stop.prevent="openPrismicModal('agb')">AGB</span>
        <span class="cursor-pointer hover:text-gray-500" @click.stop.prevent="openPrismicModal('datenschutz')">
          Datenschutz
        </span>
        <span class="cursor-pointer hover:text-gray-500" @click.stop.prevent="openPrismicModal('impressum')">
          Impressum
        </span>
        <span class="cursor-pointer hover:text-gray-500" @click.stop.prevent="openConsentModal()">Cookies</span>
      </div>
    </div>
    <DialogPrismic v-model="showPrismicModal" :uid="prismicUid"></DialogPrismic>
  </footer>
</template>
<script setup lang="ts">
const route = useRoute()

const prismicUid = ref('')
const showPrismicModal = ref(false)

const openConsentModal = () => {
  if (window.UC_UI && window.UC_UI.isInitialized()) {
    window.UC_UI.showSecondLayer()
  } else {
    window.addEventListener('UC_UI_INITIALIZED', (_e) => window.UC_UI.showSecondLayer())
  }
}

const openPrismicModal = (uid: string) => {
  prismicUid.value = uid
  showPrismicModal.value = true
}
</script>
